/**
 * Created by Talm on 14/07/2014.
 */
define(['skinExports', 'lodash', 'coreUtils'], function (skinExports, _, coreUtils) {
    'use strict';

    const MIN_GALLERY_HEIGHT = 70;

    function getSkinHeightDiff(skinName) {
        const exports = skinExports[skinName];
        return exports && exports.heightDiff || 0; // eslint-disable-line no-mixed-operators
    }

    function getSkinWidthDiff(skinName) {
        const exports = skinExports[skinName];
        return exports && exports.widthDiff || 0; // eslint-disable-line no-mixed-operators
    }

    function getGalleryHeight(currentNumberOfRows, newNumberOfRows, margin, skinName, height, itemHeightFromProp) {
        const marginBetweenImages = margin;
        const heightDiff = this.getSkinHeightDiff(skinName);
        const itemHeight = itemHeightFromProp || coreUtils.matrixCalculations.getItemHeight(margin, height, currentNumberOfRows, heightDiff);
        return Math.floor(newNumberOfRows * itemHeight + (newNumberOfRows - 1) * marginBetweenImages) + heightDiff; // eslint-disable-line no-mixed-operators
    }

    function getDisplayerHeightDiff(displayerSkinObj, displayerSkinParams, displayMode) { // eslint-disable-line complexity
        let diff = 0;
        const imgHeightDiff = parseInt(displayerSkinParams.imgHeightDiff && displayerSkinParams.imgHeightDiff.value, 10) || 0;
        const topPadding = parseInt(displayerSkinParams.topPadding && displayerSkinParams.topPadding.value, 10) || 0;

        if (imgHeightDiff || topPadding) {
            diff = imgHeightDiff + topPadding;
        } else if (displayerSkinObj && displayerSkinObj.exports) {
            if (displayMode === 'mobileView') {
                diff = _.isNumber(displayerSkinObj.exports.m_heightDiff) ? displayerSkinObj.exports.m_heightDiff : displayerSkinObj.exports.heightDiff || 0;
            } else {
                diff = displayerSkinObj.exports.heightDiff || 0;
            }
        }
        return diff;
    }

    function getDisplayerWidthDiff(displayerSkinObj, displayMode) {
        let diff = 0;

        if (displayerSkinObj && displayerSkinObj.exports) {
            if (displayMode === 'mobileView') {
                diff = _.isNumber(displayerSkinObj.exports.m_widthDiff) ? displayerSkinObj.exports.m_widthDiff : displayerSkinObj.exports.widthDiff || 0;
            } else {
                diff = displayerSkinObj.exports.widthDiff || 0;
            }
        }

        return diff;
    }

    return {
        getSkinHeightDiff,
        getSkinWidthDiff,
        getGalleryHeight,
        getDisplayerHeightDiff,
        getDisplayerWidthDiff,
        MIN_GALLERY_HEIGHT
    };
});
